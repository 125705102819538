import React from 'react';
import {BooleanField, Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import {DateTimeField} from "../../components";

const SuperAdminUserFilter = (props) => (
  <Filter {...props}>
    <TextInput label="ra.action.search" source="q" alwaysOn />
  </Filter>
);

export const SuperAdminUserList = props => (
  <List filters={<SuperAdminUserFilter />} {...props} exporter={false}>
    <Datagrid rowClick="edit" >
      <TextField source="userName" />
      <TextField source="fullName" />
      <ReferenceField source="tenantId" reference="tenants" allowEmpty>
        <TextField source="name" />
      </ReferenceField>
        <TextField source="externalClientId"/>
      <BooleanField source="enabled" />
      <BooleanField source="locked" />
        <TextField source="failOtpCount" />
        <DateTimeField source="lockTime" format="DD/MM/YYYY HH:mm:ss"/>
    </Datagrid>
</List>
);

export default SuperAdminUserList
