import React from 'react';
import {CardActions, CreateButton, Datagrid, Filter, List, ReferenceField, TextField, TextInput} from 'react-admin';
import {
    BillAuthStatusField,
    DateTimeField,
    DownloadFileContentButton,
    EditButtonWithAuthStatus
} from "../../components";
import DownloadTemplateFileButtonV2 from "../../components/DownloadTemplateFileButtonV2";

const IntroductionFileFilter = (props) => (
    <Filter {...props}>
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

const PostActions = ({
                         bulkActions,
                         basePath,
                         currentSort,
                         displayedFilters,
                         exporter,
                         filters,
                         filterValues,
                         onUnselectItems,
                         resource,
                         selectedIds,
                         showFilter,
                         total
                     }) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        })}
        {/* Add your custom actions */}
        <DownloadTemplateFileButtonV2 basePath="/files/templates/mau_lo_ne_nep.zip"
                                      btnLabel="resources.partnerDisciplineBatches.template"/>
        <CreateButton basePath={basePath}/>
    </CardActions>
);
export const PartnerDisciplineBatchList = props => (
    <List filters={<IntroductionFileFilter/>} {...props} exporter={false} actions={<PostActions/>}>
        <Datagrid>
            <TextField source="id"/>
            <TextField source="description"/>
            <DateTimeField source="startDate" format="DD/MM/YYYY"/>
            <DateTimeField source="endDate" format="DD/MM/YYYY"/>
            <ReferenceField source="semesterId" reference="semesters" allowEmpty>
                <TextField source="name"/>
            </ReferenceField>
            <BillAuthStatusField source="authStatus"/>
            <TextField source="lastUpdatedBy"/>
            <DateTimeField source="lastUpdatedOn" format="DD/MM/YYYY HH:mm"/>
            <DownloadFileContentButton/>
            <EditButtonWithAuthStatus/>
        </Datagrid>
    </List>
);

export default PartnerDisciplineBatchList
