import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {AutocompleteInput, DateInput, ReferenceInput, required, SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";
import {
    FileUploadComponent,
    schoolYears,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import {post} from "axios";

export class PartnerDisciplineBatchCreate extends React.Component {

    state = {
        description: '',
        enable: true,
        isRedirect: false,
        file: null
    }

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            semesterId: '',
            startDate: new Date(),
            endDate: new Date(),
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeStartDate = (val) => {
        this.setState({startDate: val});
    }

    changeEndDate = (val) => {
        this.setState({endDate: val});
    }

    changeSemester = (e, val) => {
        this.setState({semesterId: val});
    }

    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        // lưu lô thực đơn
        const {description, semesterId, startDate, endDate} = this.state;
        const batchData = {
            description,
            startDate, endDate, semesterId
        }
        const url = `/api/partnerDisciplineBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            const batchId = rs.data.id
            this.setState({
                batchId: batchId
            })
            this.fileUpload(this.state.file, batchId).then((res) => {
                this.setState({
                    isLoading: false
                })
                if (res.data && res.data.size > 0) {
                    showSuccessNotify("Xử lý file thành công. Vui lòng kiểm tra file kết quả.");
                    let fileNameFromSv = res.headers['attachmentfilename'];
                    let blob = new Blob([res.data]);
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileNameFromSv ? fileNameFromSv : 'ket_qua.zip';
                    link.click();
                    this.setState({
                        isRedirect: true
                    })
                } else {
                    showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
                }
            })
                .catch(error => {
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                    this.setState({
                        isLoading: false,
                    })
                });
        })
    }

    fileUpload(file, batchId) {
        const url = `${env.baseURL}/api/partnerDisciplineBatchUpload/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', batchId);
        const config = {
            responseType: 'blob',
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token
            }
        }
        return post(url, formData, config)
    }

    save = () => {
        if (!this.validate()) {
            return;
        }
        const {description, semesterId, endDate, startDate} = this.state;
        const data = {
            description,
            semesterId, endDate, startDate
        }

        const url = `/api/partnerDisciplineBatches`;
        return axiosInstance.post(url, data).then(rs => {
            this.cancel();
        }, err => {
            showWarningNotify(err.message);
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    validate = () => {
        const {description, semesterId} = this.state;
        if (!description) {
            showWarningNotify('Vui lòng nhâp mô tả');
            return false;
        }
        if (!semesterId) {
            showWarningNotify('Vui lòng chọn học kỳ');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/partnerDisciplineBatches'/>;
        }
        const {description} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo lô nề nếp"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        id="standard-required"
                                        label="Mô tả"
                                        margin="normal"
                                        required={true}
                                        value={description}
                                        onChange={(e) => this.changeDescription(e.target.value)}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <ReferenceInput source="semesterId"
                                                    validate={required()}
                                                    {...this.props}
                                                    reference="semesters"
                                                    filter={{schoolYearId: schoolYears[0].id}}
                                                    onChange={(e, value) => this.changeSemester(e, value)}
                                                    perPage={999}>
                                        <AutocompleteInput optionText={({
                                                                            name,
                                                                            startDate,
                                                                            endDate
                                                                        }) => `${name} (${startDate} - ${endDate})`}/>
                                    </ReferenceInput>
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <DateInput source="startDate" {...this.props} validate={required()}
                                               onChange={(e, value) => this.changeStartDate(value)}/>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <DateInput source="endDate" {...this.props} validate={required()}
                                               onChange={(e, value) => this.changeEndDate(value)}/>
                                </Grid>
                                <Grid container spacing={8}>
                                    {
                                        this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                            <FileUploadComponent
                                                onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                onFileUpload={this.onFileUpload}
                                            />
                                            {
                                                this.state.isLoading && <CircularProgress color="secondary"/>
                                            }
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(PartnerDisciplineBatchCreate);
