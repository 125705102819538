import AccessDeviceIcon from '@material-ui/icons/ViewList';

import CustomerQrBatchList from './CustomerQrBatchList';
import IntroductionFileCreate from './CustomerQrBatchCreate';

export default {
    list: CustomerQrBatchList,
    create: IntroductionFileCreate,
    icon: AccessDeviceIcon,
};
