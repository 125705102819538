import React, {useState} from 'react';
import {ReferenceInput, Title} from "react-admin";
import CardContent from "@material-ui/core/CardContent";
import {ReportForm, schoolYears, showErrorNotify, TreeCheckboxInput} from "../../components";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import env from "../../env";
import {downloadFilePost} from "../../components/FileDownload";

const StudentRelationshipReport = (props) => {

    const [unitIds, setUnitIds] = useState([])

    const getReport = () => {
        if (unitIds.length === 0) {
            showErrorNotify("Vui lòng chọn danh sách lớp")
            return;
        }
        downloadFilePost(`${env.reportURL}/reports/student-relationships`, 'ds_thong_tin_hoc_sinh.xlsx', unitIds)
    }

    const validate = (values) => {
        setUnitIds(values.unitIds)
        return {}
    }
    return <Card>
        <Title title="resources.student-relationship-report.name"/>
        <CardContent>
            <ReportForm validate={validate}
                        excelReport={getReport}>
                <Grid item xs={12} sm={6}>
                    <label>Danh sách lớp *</label>
                    <div style={{maxHeight: '300px', overflow: 'auto'}}>
                        <ReferenceInput source="unitIds"
                                        reference="units"
                                        perPage={999}
                                        filter={{clazzWithParents: true, schoolYear: schoolYears[0].id}}
                                        {...props} >
                            <TreeCheckboxInput optionValue="id" optionText="name" expandTopLevel={true}/>
                        </ReferenceInput>
                    </div>
                </Grid>
            </ReportForm>
        </CardContent>
    </Card>
};

export default StudentRelationshipReport;
