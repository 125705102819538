import React, {Component} from 'react';
import {connect} from 'react-redux';
import {CircularProgress, Grid} from '@material-ui/core'
import {AutocompleteInput, DateInput, Edit, ReferenceInput, required, SimpleForm, TextInput} from 'react-admin';
import {
    CustomToolbar,
    FileUploadComponent,
    schoolYears,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import {post} from "axios";
import {Redirect} from 'react-router-dom';

export class PartnerDisciplineBatchEdit extends Component {

    constructor(props) {
        super(props);
        this.state = {
            batchId: props.id,
            showValidateBtn: false,
            showValidationData: false,
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (this.state.file == null) {
            return;
        }
        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        this.fileUpload(this.state.file).then((res) => {
            this.setState({
                isLoading: false
            })
            if (res.data && res.data.size > 0) {
                showSuccessNotify("Xử lý file thành công. Vui lòng kiểm tra file kết quả.");
                let fileNameFromSv = res.headers['attachmentfilename'];
                let blob = new Blob([res.data]);
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = fileNameFromSv ? fileNameFromSv : 'ket_qua.zip';
                link.click();
                this.setState({
                    isRedirect: true
                })
            } else {
                showWarningNotify("File không khả dụng, vui lòng thử lại hoặc liên hệ quản trị viên");
            }
        })
            .catch(error => {
                showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                this.setState({
                    isLoading: false,
                    showValidationData: false,
                    validationData: [],
                    foodTypes: []
                })
            });
    }

    fileUpload(file) {
        const url = `${env.baseURL}/api/partnerDisciplineBatchUpload/validate-item`;
        const token = localStorage.getItem('token');
        const formData = new FormData();
        formData.append('file', file);
        formData.append('batchId', this.state.batchId);
        const config = {
            responseType: 'blob',
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': token,
            }
        }
        return post(url, formData, config)
    }

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };


    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/partnerDisciplineBatches'/>;
        }
        return (
            <Edit {...this.props}>
                <SimpleForm toolbar={<CustomToolbar/>}>
                    <Grid container spacing={8}>
                        <Grid item xs={12} sm={6}>
                            <TextInput source="description" validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <ReferenceInput source="semesterId"
                                            validate={required()}
                                            reference="semesters"
                                            filter={{schoolYearId: schoolYears[0].id}}
                                            perPage={999}>
                                <AutocompleteInput optionText={({
                                                                    name,
                                                                    startDate,
                                                                    endDate
                                                                }) => `${name} (${startDate} - ${endDate})`}/>
                            </ReferenceInput>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <DateInput source="startDate" validate={required()}/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <DateInput source="endDate" validate={required()}/>
                        </Grid>
                    </Grid>
                    <Grid container spacing={8}>
                        {
                            this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                <FileUploadComponent onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                     onFileUpload={this.onFileUpload}
                                />
                                {
                                    this.state.isLoading && <CircularProgress color="secondary"/>
                                }
                            </Grid>
                        }

                    </Grid>
                </SimpleForm>
            </Edit>
        )
    }
}

export default connect(null, null)(PartnerDisciplineBatchEdit);
