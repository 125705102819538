import React from 'react';
import {Edit, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid} from '../../components'
import SemesterFields from './SemesterFields'

export const SemesterEdit = props => (
    <Edit {...props}>
        <SimpleForm toolbar={<CustomToolbar/>}>
            <Grid container spacing={8}>
                <SemesterFields/>
            </Grid>
        </SimpleForm>
    </Edit>
);

export default SemesterEdit
