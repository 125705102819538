import React from 'react';
import {Create, SimpleForm} from 'react-admin';

import {CustomToolbar, Grid, schoolYears} from '../../components'
import SemesterFields from './SemesterFields'

const defaultValue = {
    displayOrder: 100,
    schoolYearId: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null
}
export const SemesterCreate = props => (
    <Create {...props}>
        <SimpleForm toolbar={<CustomToolbar/>} defaultValue={defaultValue} redirect="list">
            <Grid container spacing={8}>
                <SemesterFields/>
            </Grid>
        </SimpleForm>
    </Create>
);

export default SemesterCreate
