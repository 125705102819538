import React from 'react';
import Grid from '@material-ui/core/Grid'
import {BooleanInput, DateInput, ReferenceInput, required, SelectInput, TextInput} from 'react-admin';

const SemesterFields = props => (
    <React.Fragment>
        <Grid item xs={12} sm={6}>
            <TextInput source="name" validate={required()} {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <ReferenceInput source="schoolYearId" reference="schoolYears" validate={required()} allowEmpty {...props} >
                <SelectInput optionText="name"/>
            </ReferenceInput>
        </Grid>
        <Grid item xs={12} sm={6}>
            <DateInput source="startDate" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <DateInput source="endDate" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <TextInput type="number" source="displayOrder" {...props} />
        </Grid>
        <Grid item xs={12} sm={6}>
            <BooleanInput source="useDefault" {...props} />
        </Grid>
    </React.Fragment>
)

export default SemesterFields
