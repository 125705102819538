import React from 'react';
import {Route} from 'react-router-dom';

import Report from './pages/Report';
import StudentAttendance from './reports/StudentAttendanceReport';
import ClazzAttendance from './reports/ClazzAttendanceReport';
import SchoolAttendance from './reports/SchoolAttendanceReport';
import OnlineAttendance from './reports/OnlineAttendanceReport';
import TestPage from './TestPage';
import InReport from "./reports/InReport";
import AttendanceInOutReport from "./reports/AttendanceInOutReport";
import AttendanceTimeKeepingReport from "./reports/TimeKeepingReport";
import VotingReport from "./reports/VotingReport";
import MissingVotingReport from "./reports/MissingVotingReport";
import AbsentReport from "./reports/AbsentReport";
import SemiBoardingAbsentReport from "./reports/SemiBoardingAbsentReport";
import SemiBoardingMissingCardReport from "./reports/SemiBoardingMissingCardReport";
import StudentEnrollmentMigrate from "./pages/StudentEnrollment/StudentEnrollmentMigrate";
import CardPermissionCreate from "./pages/CardPermission/CardPermissionCreate";
import DateKeepingReport from "./reports/DateKeepingReport";
import GuestReport from "./reports/GuestReport";
import SchoolFeeImport from "./pages/School/SchoolFeeImport";
import AttendanceSimu from "./pages/AttendanceSimu";
import BusAttendanceReport from "./reports/BusAttendanceReport";
import SemiBoardingMissingReport from "./reports/SemiBoardingMissingReport";
import PaymentPaidByProduct from "./reports/PaymentPaidByProduct";
import BillBatchTemplate from "./reports/BillBatchTemplate";
import BillBatchItemNotify from "./reports/BillBatchItemNotify";
import BillItemNotifyByBatch from "./reports/BillItemNotifyByBatch";
import PaymentByDaysReport from "./reports/PaymentByDaysReport";
import PaymentSchoolReport from "./reports/PaymentBySchoolReport";
import DeptStudentsReport from "./reports/DeptStudentsReport";
import MealCheckingReport from "./reports/MealCheckingReport";
import OnlineAttendanceInfo from "./reports/OnlineAttendanceInfo";
import PresentDeputyReport from "./reports/PresentDeputyReport";
import WeeklyReport from "./reports/WeeklyReport";
import StudentsHaveRemain from "./reports/StudentsHaveRemain";
import ReceiptUsageReport from "./reports/ReceiptUsageReport";
import PaidFullDayStudentsReport from "./reports/PaidFullDayStudentsReport";
import EventReport from "./reports/EventReport";
import ListPaidByProduct from "./reports/ListPaidByProduct";
import DiligenceReport from "./reports/DiligenceReport";
import PaidByMonthsReport from "./reports/PaidByMonthsReport";
import VnEduStudentReport from "./reports/VnEduStudentReport";
import VotingInfo from "./reports/VotingInfo";
import BangKeTungDanhMuc from "./reports/BangKeTungDanhMuc";
import BaoCaoSuDungBienLai from "./reports/BaoCaoSuDungBienLai";
import PHKichHoatApp from "./reports/PHKichHoatApp";
import BaoCaoHoaDonBiHuy from './reports/BaoCaoHoaDonBiHuy';
import HsDaDongTienTheoDanhMuc from "./reports/HsDaDongTienTheoDanhMuc";
import BaoCaoTamUngNapThe from "./reports/hospital/BaoCaoTamUngNapThe";
import BaoCaoHoanTamUngNapThe from "./reports/hospital/BaoCaoHoanTamUngNapThe";
import BaoCaoSoDuTaiKhoan from "./reports/hospital/SoDuTaiKhoan";
import ThanhToanVienPhiReport from "./reports/hospital/ThanhToanVienPhiReport";
import UnpaidBillNotify from "./reports/UnpaidBillNotify";
import ThongBaoHocPhi from "./reports/ThongBaoHocPhi";
import TBNhacNo from "./reports/TBNhacNo";
import ChiTraTienReport from "./reports/ChiTraTienReport";
import TongHopKhoanThuReport from './reports/TongHopKhoanThuReport';
import ChiTietHoaDownReport from './reports/ChiTietHoaDownReport';
import BaoCaoChiTietBienLai from './reports/BaoCaoChiTietBienLai';
import BaoCaoGiaoDichTaiKhoan from './reports/taiKhoanHocPhi/GiaoDichTaiKhoan';
import BaoCaoThuHocPhiDoiTacOnline from "./reports/BaoCaoThuHocPhiDoiTacOnline";
import BaoCaoDiemDanhGiaoVien from './reports/BaoCaoDiemDanhGiaoVien';
import SuatAnBanTru from "./reports/SuatAnBanTru/SuatAnBanTru";
import PhieuDiemDanhThang from "./reports/PhieuDiemDanhThang";
import BcDanhSachHS from "./reports/BcDanhSachHS";
import ThongKeLuotRaVao from "./reports/ThongKeLuotRaVao";
import ThongKeSoLieuDoNhietDo from "./reports/ThongKeSoLieuDoNhietDo";
import ChiTietNhietDoCao from "./reports/ChiTietNhietDoCao";
import ChiTietNhietDoNv from "./reports/ChiTietNhietDoNv";
import BaoCaoDiemDanhVang from "./reports/DiemDanhVang";
import TongHopDungThe from "./reports/TongHopDungThe";
import LuotKhamDungThe from "./reports/LuotKhamDungThe";
import DsTkDinhDanh from "./reports/DsTkDinhDanh";
import DsMaQrNv from "./reports/DsMaQr/DsMaQrNv";
import DsMaQrHS from "./reports/DsMaQr/DsMaQrHS";
import DsMaQrCD from "./reports/DsMaQr/DsMaQrCD";
import customerSubjectResultTemplate from "./reports/CustomerSubjectResultTemplate";
import TongHopBienLaiVaHoaDon from "./reports/TongHopBienLaiVaHoaDon";
import ECardReport from "./reports/ECardReport";
import TheDienTuSign from "./reports/TheDienTuSign";
import BcChamCongThang from "./reports/BcChamCong2";
import SoDiemDanh from "./reports/SoDiemDanh";
import BaoCaoBienLaiBiHuy from "./reports/BienLaiBiHuy";
import BcChamThe from "./reports/BcChamThe";
import BcTheDienTu from "./reports/BcTheDienTu";
import BangGioDiemDanh from "./reports/BangGioDiemDanh";
import DiemDanhLopHocPhan from "./reports/DiemDanhLopHocPhan";
import TongHopDiemDanhHocPhan from "./reports/TongHopDiemDanhHocPhan";
import ChiTietThuPhiTheoNoiDung from "./reports/ChiTietThuPhiTheoNoiDung";
import BatchPrintReceipt from "./reports/BatchPrintReceipt";
import BcChiTietThu from "./reports/bcChiTietThu";
import StudentEnrollmentPromote from "./pages/StudentEnrollment/StudentEnrollmentPromote";
import BillBatchItemNotifyV2 from "./reports/BillBatchItemNotifyV2";
import ContributionBillBatchTemplate from "./reports/ContributionBillBatchTemplate";
import CustomerImageBatch from "./reports/CustomerImageBatch";
import MealRegistration from "./reports/MealRegistration";
import soLuongAnBanTruKeToan from "./reports/SoLuongAnBanTruKeToan";
import SoLuongAnBanTru from "./reports/SoLuongAnBanTru";
import UploadLoHinhMoThe from "./reports/UploadLoHinhMoThe";
import TongHopDonXinNghi from "./reports/TongHopDonXinNghi";
import DonXinNghiHS from "./reports/DonXinNghiHS";
import FullDayCalendarTemplate from "./pages/FullDayCalendar";
import ChiTietThuPhiTheoNoiDungVaNguoiThu from "./reports/ChiTietThuPhiTheoNoiDungVaNguoiThu";
import TongHopDuyetDiemDanh from "./reports/TongHopDuyetDiemDanh";
import ThongKeLuotChamThe from "./reports/ThongKeLuotChamThe";
import DiemDanhSo from "./reports/DiemDanhSo";
import CreateAttendance from "./reports/CreateAttendance";
import StudentAttendanceBookReport from "./reports/StudentAttendanceBookReport";
import TBHocPhiTheoKeHoach from "./reports/TBHocPhiTheoKeHoach";
import SmsDashboard from "./pages/SmsDashboard/SmsDashboard";
import ThongKeSmsTheoNguoi from "./reports/ThongKeSmsTheoNguoi";
import ProfileChangeLogCart from "./reports/ProfileChangeLogCart";
import ProfileChangeLogCartHistory from "./reports/ProfileChangeLogCartHistory";
import CauHinhCongBoDiem from "./reports/ExamResultConfigBatch";
import CustomerHealthBatchTemplate from "./reports/CustomerHealthBatchTemplate";
import CustomerCertificateBatchTemplate from "./reports/CustomerCertificateBatchTemplate";
import BillViewEditByMonth from "./pages/BillViewEditByMonth";
import bcDuyetNghi from "./reports/BcDuyetNghi";
import relationshipMigrationBatchTemplate from "./reports/RelationshipMigrationBatchTemplate";
import TaoDangKySuatAn from "./reports/TaoDangKySuatAn";
import BcNguTruaBT from "./reports/BcNguTruaBT";
import BcDiemDanhBT from "./reports/BcDiemDanhBT";
import EmployeeOffRequestReport from "./reports/EmployeeOffRequestReport";
import EmployeeOffRequestDetailReport from "./reports/EmployeeOffRequestDetailReport";
import KiotOrders from "./reports/KiotOrders";
import KiotImportOrders from "./reports/KiotImportOrders";
import KiotDeliveredOrders from "./reports/KiotDeliveredOrders";
import FullDayCalendarOffTemplate from "./pages/FullDayCalendarOffTemplate";
import CardList from "./pages/Card/CardList";
import UpdateCardHistory from "./pages/Card/UpdateCardHistory";
import UpdateCardTemplate from "./pages/Card/UpdateCardTemplate";
import BcTuyenSinh from "./reports/BcTuyenSinh";
import DonTuyenSinh from "./reports/DonTuyenSinh";
import AdmissionsCardApplication from "./reports/AdmissionsCardApplication";
import FaceRemove from "./pages/Face/FaceRemove";
import TraCuuTuyenSinhV2 from "./public/TraCuuTuyenSinh/TraCuuTuyenSinhV2";
import AdmissionDashboard from "./reports/AdmissionDashboard";
import BcTrangThaiTuyenSinh from "./reports/BcTrangThaiTuyenSinh";
import BcTuongTacNhanXet from "./reports/BcTuongTacNhanXet";
import QuickBillPayment from "./pages/QuickBillPayment";
import ExemptionPolicyReport from "./reports/ExemptionPolicyReport";
import ChiTietThuPhiTheoNoiDungVaNguoiThuHD from "./reports/help-desk/ChiTietThuPhiTheoNoiDungVaNguoiThuHD";
import BcNeNepTheoLop from "./reports/bcNeNepTheoLop";
import BcXhThiDua from "./reports/bcXhThiDua";
import BillBatchPrintingA6 from "./reports/BillBatchPrintingA6";
import TongHopDatHang from "./reports/TongHopDatHang";
import ChamTheKhongHopLe from "./reports/ChamTheKhongHopLe";
import TongHopNhanSuatAn from "./reports/TongHopNhanSuatAn";
import BillBatchItemMergeNotify from "./reports/BillBatchItemMergeNotify";
import ChamTheNhanSuatAn from "./reports/ChamTheNhanSuatAn";
import StudentRelationshipReport from "./reports/StudentRelationshipReport";

export default [
  <Route exact path="/test" component={TestPage}/>,

  <Route exact path="/reports" component={Report}/>,
  <Route exact path="/reportStudentAttendance" component={StudentAttendance}/>,
  <Route exact path="/reportClazzAttendance" component={ClazzAttendance}/>,
  <Route exact path="/reportSchoolAttendance" component={SchoolAttendance}/>,
  <Route exact path="/eventInfo/:eventId" component={OnlineAttendance} noLayout/>,
  <Route exact path="/inReport" component={InReport}/>,
  <Route exact path="/attendanceInOutReport" component={AttendanceInOutReport}/>,
  <Route exact path="/timeKeepingReport" component={AttendanceTimeKeepingReport}/>,
  <Route exact path="/votingReport" component={VotingReport}/>,
  <Route exact path="/missingVotingReport" component={MissingVotingReport}/>,
  <Route exact path="/absentReport" component={AbsentReport}/>,
  <Route exact path="/semiBoardingAbsentReport" component={SemiBoardingAbsentReport}/>,
  <Route exact path="/semiBoardingMissingCardReport" component={SemiBoardingMissingCardReport}/>,
  <Route exact path="/promote" component={StudentEnrollmentPromote}/>,
  <Route exact path="/cardPermission" component={CardPermissionCreate}/>,
  <Route exact path="/dateKeepingReport" component={DateKeepingReport}/>,
  <Route exact path="/migrateStudent" component={StudentEnrollmentMigrate}/>,
  <Route exact path="/guestReport" component={GuestReport}/>,
  <Route exact path="/schoolFeeMessaging" component={SchoolFeeImport}/>,
  <Route exact path="/attendanceSimu" component={AttendanceSimu}/>,
  <Route exact path="/busAttendanceReport" component={BusAttendanceReport}/>,
  <Route exact path="/semiBoardingMissingReport" component={SemiBoardingMissingReport}/>,
  <Route exact path="/paymentPaidByProduct" component={PaymentPaidByProduct}/>,
  <Route exact path="/billBatchTemplate" component={BillBatchTemplate}/>,
  <Route exact path="/billBatchItemNotify" component={BillBatchItemNotify}/>,
  <Route exact path="/paymentByDaysReport" component={PaymentByDaysReport}/>,
  <Route exact path="/paymentSchoolReport" component={PaymentSchoolReport}/>,
  <Route exact path="/deptStudentsReport" component={DeptStudentsReport}/>,
  <Route exact path="/mealCheckingReport" component={MealCheckingReport}/>,
  <Route exact path="/onlineAttendanceInfo/:eventId" component={OnlineAttendanceInfo} noLayout/>,
  <Route exact path="/presentDeputyReport" component={PresentDeputyReport}/>,
  <Route exact path="/weeklyReport" component={WeeklyReport}/>,
  <Route exact path="/studentsHaveRemain" component={StudentsHaveRemain}/>,
  <Route exact path="/receiptUsage" component={ReceiptUsageReport}/>,
  <Route exact path="/paidFullDayStudentsReport" component={PaidFullDayStudentsReport}/>,
  <Route exact path="/eventReport" component={EventReport}/>,
  <Route exact path="/listPaidByProducts" component={ListPaidByProduct} />,
  <Route exact path="/diligenceReport" component={DiligenceReport} />,
  <Route exact path="/paidByMonthsReport" component={PaidByMonthsReport} />,
  <Route exact path="/vnEduStudentsReport" component={VnEduStudentReport} />,
  <Route exact path="/votingInfo/:pollId/:styleCode/:totalParticipant" component={VotingInfo} noLayout/>,
  <Route exact path="/bangKeTungDanhMuc" component={BangKeTungDanhMuc} />,
  <Route exact path="/baoCaoSuDungBienLai" component={BaoCaoSuDungBienLai} />,
  <Route exact path="/PHKichHoatApp" component={PHKichHoatApp} />,
  <Route exact path="/baoCaoHoaDonBiHuy" component={BaoCaoHoaDonBiHuy} />,
  <Route exact path="/hsDaDongTienTheoDanhMuc" component={HsDaDongTienTheoDanhMuc} />,
  <Route exact path="/baoCaoTamUngNapThe" component={BaoCaoTamUngNapThe} />,
  <Route exact path="/baoCaoHoanTamUngNapThe" component={BaoCaoHoanTamUngNapThe} />,
  <Route exact path="/baoCaoSoDuTaiKhoan" component={BaoCaoSoDuTaiKhoan} />,
  <Route exact path="/unpaidBillNotify" component={UnpaidBillNotify} />,
  <Route exact path="/thongBaoHocPhi" component={ThongBaoHocPhi} />,
  <Route exact path="/chiTraTienReport" component={ChiTraTienReport} />,
  <Route exact path="/tongHopKhoanThuReport" component={TongHopKhoanThuReport} />,
  <Route exact path="/baoCaothanhToanVienPhi" component={ThanhToanVienPhiReport} />,
  <Route exact path="/chiTietHoaDonReport" component={ChiTietHoaDownReport} />,
  <Route exact path="/baoCaoChiTietBienLai" component={BaoCaoChiTietBienLai} />,
  <Route exact path="/baoCaoThuHocPhiDoiTacOnline" component={BaoCaoThuHocPhiDoiTacOnline} />,
  <Route exact path="/baoCaoGiaoDichTaiKhoan" component={BaoCaoGiaoDichTaiKhoan} />,
  <Route exact path="/baoCaoDiemDanhGiaoVien" component={BaoCaoDiemDanhGiaoVien} />,
  <Route exact path="/suatAnBanTru" component={SuatAnBanTru} />,
  <Route exact path="/phieuDiemDanhThang" component={PhieuDiemDanhThang} />,
  <Route exact path="/bcDanhSachHS" component={BcDanhSachHS} />,
  <Route exact path="/thongKeLuotRaVao" component={ThongKeLuotRaVao} />,
  <Route exact path="/thongKeSoLieuDoNhietDo" component={ThongKeSoLieuDoNhietDo} />,
  <Route exact path="/chiTietNhietDoCao" component={ChiTietNhietDoCao} />,
  <Route exact path="/chiTietNhietDoNv" component={ChiTietNhietDoNv} />,
  <Route exact path="/baoCaoDiemDanhVang" component={BaoCaoDiemDanhVang} />,
  <Route exact path="/tongHopDungThe" component={TongHopDungThe} />,
  <Route exact path="/luotKhamDungThe" component={LuotKhamDungThe} />,
  <Route exact path="/dsTkDinhDanh" component={DsTkDinhDanh} />,
  <Route exact path="/dsMaQrNv" component={DsMaQrNv} />,
  <Route exact path="/dsMaQrHS" component={DsMaQrHS} />,
  <Route exact path="/dsMaQrCD" component={DsMaQrCD} />,
  <Route exact path="/customerSubjectResultTemplate" component={customerSubjectResultTemplate} />,
  <Route exact path="/tongHopBienLaiVaHoaDon" component={TongHopBienLaiVaHoaDon} />,
  <Route exact path="/DSTheDienTu" component={ECardReport} />,
  <Route exact path="/uploadTheDienTuSign" component={TheDienTuSign} />,
  <Route exact path="/bcChamCong" component={BcChamCongThang} />,
  <Route exact path="/soDiemDanh" component={SoDiemDanh} />,
  <Route exact path="/baoCaoBienLaiBiHuy" component={BaoCaoBienLaiBiHuy} />,
  <Route exact path="/bcChamThe" component={BcChamThe} />,
  <Route exact path="/bcTheDienTu" component={BcTheDienTu}/>,
  <Route exact path="/bangGioDiemDanh" component={BangGioDiemDanh}/>,
  <Route exact path="/diemDanhLopHocPhan" component={DiemDanhLopHocPhan}/>,
  <Route exact path="/tongHopDiemDanhHocPhan" component={TongHopDiemDanhHocPhan}/>,
  <Route exact path="/chiTietThuPhiTheoNoiDung" component={ChiTietThuPhiTheoNoiDung}/>,
  <Route exact path="/inNhieuHoaDon" component={BatchPrintReceipt}/>,
  <Route exact path="/bcChiTietThu" component={BcChiTietThu}/>,
  <Route exact path="/contributionBillBatchTemplate" component={ContributionBillBatchTemplate}/>,
  <Route exact path="/mealRegistration1" component={() => <MealRegistration reportNumber={1}/>}/>,
  <Route exact path="/mealRegistration2" component={() => <MealRegistration reportNumber={2}/>}/>,
  <Route exact path="/mealRegistration3" component={() => <MealRegistration reportNumber={3}/>}/>,
  <Route exact path="/billBatchItemNotifyV2" component={BillBatchItemNotifyV2}/>,
  <Route exact path="/bill-batch-printing-a6" component={BillBatchPrintingA6}/>,
  <Route exact path="/customerImageBatch" component={CustomerImageBatch}/>,
  <Route exact path="/soLuongAnBanTruKeToan" component={soLuongAnBanTruKeToan}/>,
  <Route exact path="/soLuongAnBanTru" component={SoLuongAnBanTru}/>,
  <Route exact path="/cardApplicationPhotoBatches" component={UploadLoHinhMoThe}/>,
  <Route exact path="/tongHopDonXinNghi" component={TongHopDonXinNghi}/>,
  <Route exact path="/donXinNghiHS" component={DonXinNghiHS}/>,
  <Route exact path="/tkbBanTru" component={FullDayCalendarTemplate}/>,
  <Route exact path="/bcChiTietPhiVaNguoiThu" component={ChiTietThuPhiTheoNoiDungVaNguoiThu}/>,
  <Route exact path="/tongHopDuyetDiemDanh" component={TongHopDuyetDiemDanh}/>,
  <Route exact path="/thongKeLuotChamThe" component={ThongKeLuotChamThe}/>,
  <Route exact path="/create-attendance" component={CreateAttendance}/>,
  <Route exact path="/diemDanhSo" component={DiemDanhSo}/>,
  <Route exact path="/student-attendance-book-report" component={StudentAttendanceBookReport}/>,
  <Route exact path="/billItemNotifyByBatch" component={BillItemNotifyByBatch}/>,
  <Route exact path="/tbHocPhiTheoKeHoach" component={TBHocPhiTheoKeHoach}/>,
  <Route exact path="/smsDashboard" component={SmsDashboard}/>,
  <Route exact path="/thongKeSmsTheoNguoi" component={ThongKeSmsTheoNguoi}/>,
  <Route exact path="/profileChangeLogCart" component={ProfileChangeLogCart}/>,
  <Route exact path="/profileChangeLogCartHistory" component={ProfileChangeLogCartHistory}/>,
  <Route exact path="/cauHinhCongBoDiem" component={CauHinhCongBoDiem}/>,
  <Route exact path="/certificateBatchTemplate" component={CustomerCertificateBatchTemplate}/>,
  <Route exact path="/tbNhacNo" component={TBNhacNo}/>,
  <Route exact path="/billViewEditByMonth" component={BillViewEditByMonth}/>,
  <Route exact path="/bcDuyetNghi" component={bcDuyetNghi}/>,
  <Route exact path="/relationshipMigrationBatchTemplate" component={relationshipMigrationBatchTemplate}/>,
  <Route exact path="/taoDangKySuatAn" component={TaoDangKySuatAn}/>,
  <Route exact path="/bcNguTruaBanTru" component={BcNguTruaBT}/>,
  <Route exact path="/bcDiemDanhBanTru" component={BcDiemDanhBT}/>,

  <Route exact path="/employee-off-request-report" component={EmployeeOffRequestReport}/>,
  <Route exact path="/employee-off-request-detail-report" component={EmployeeOffRequestDetailReport}/>,

  <Route exact path="/kiotOrders" component={KiotOrders}/>,
  <Route exact path="/kiotImportOrders" component={KiotImportOrders}/>,
  <Route exact path="/kiotDeliveredOrders" component={KiotDeliveredOrders}/>,
  <Route exact path="/full-day-calendar-off-template" component={FullDayCalendarOffTemplate}/>,
  <Route exact path="/cards" component={CardList}/>,
  <Route exact path="/update-card-template" component={UpdateCardTemplate}/>,
  <Route exact path="/update-card-history" component={UpdateCardHistory}/>,
  <Route exact path="/customerHealthBatchTemplate" component={CustomerHealthBatchTemplate}/>,
  <Route exact path="/donTuyenSinh" component={DonTuyenSinh}/>,
  <Route exact path="/bcTuyenSinh" component={BcTuyenSinh}/>,
  // <Route exact path="/tra-cuu-tuyen-sinh" component={TraCuuTuyenSinh} noLayout/>,
  <Route exact path="/tuyen-sinh-thpt-gia-dinh" noLayout
         component={() => <TraCuuTuyenSinhV2 selectedTenant={{
           id: 8,
           name: 'THPT Gia Định',
           logo: '/file/tn/8_1702953311381RFr.png'
         }}/>}/>,
  <Route exact path="/tuyen-sinh-ttgdtx-chu-van-an" noLayout
         component={() => <TraCuuTuyenSinhV2 selectedTenant={{
           id: 21,
           name: 'Trung Tâm GDTX Chu Văn An',
           logo: '/file/tn/21_1702953311900fur.png'
         }}/>}/>,
  <Route exact path="/tuyen-sinh-thpt-nguyen-an-ninh" noLayout
         component={() => <TraCuuTuyenSinhV2 selectedTenant={{
           id: 67,
           name: 'Trường THPT Nguyễn An Ninh',
           logo: '/file/tn/67_1702953157211Gna.jpg'
         }}/>}/>,
  <Route exact path="/admissions-card-application" component={AdmissionsCardApplication}/>,
  <Route exact path="/face-remove" component={FaceRemove}/>,
  <Route exact path="/admission-dashboard" component={AdmissionDashboard}/>,
  <Route exact path="/bcTrangThaiTuyenSinh" component={BcTrangThaiTuyenSinh}/>,
  <Route exact path="/bcTuongTacNhanXet" component={BcTuongTacNhanXet}/>,
  <Route exact path="/tao-nhanh-thanh-toan" component={QuickBillPayment}/>,
  <Route exact path="/exemption-policy-report" component={ExemptionPolicyReport}/>,
  <Route exact path="/help-desk/bcChiTietPhiVaNguoiThu" component={ChiTietThuPhiTheoNoiDungVaNguoiThuHD}/>,
  <Route exact path="/bcNeNepTheoLop" component={BcNeNepTheoLop}/>,
  <Route exact path="/bcXhThiDua" component={BcXhThiDua}/>,
  <Route exact path="/tong-hop-dat-hang" component={TongHopDatHang}/>,
  <Route exact path="/chamTheKhongHopLe" component={ChamTheKhongHopLe}/>,
  <Route exact path="/tong-hop-nhan-suat-an" component={TongHopNhanSuatAn}/>,
  <Route exact path="/in-tb-hp-gop-ke-hoach" component={BillBatchItemMergeNotify}/>,
  <Route exact path="/bcChamTheNhanSuatAn" component={ChamTheNhanSuatAn}/>,
  <Route exact path="/student-relationship-report" component={StudentRelationshipReport}/>,
];
