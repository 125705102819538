import AccessDeviceIcon from '@material-ui/icons/ViewList';

import MessageBatchList from './MessageBatchList';
import MessageCreate from './MessageBatchCreate';

export default {
    list: MessageBatchList,
    create: MessageCreate,
    icon: AccessDeviceIcon,
};
