import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {change, isSubmitting, submit} from 'redux-form';
import {
    AutocompleteInput,
    Button,
    DateInput,
    fetchEnd,
    fetchStart,
    ReferenceInput,
    refreshView,
    required,
    SaveButton,
    SelectInput,
    SimpleForm,
    TextInput
} from 'react-admin';
import TextField from '@material-ui/core/TextField';
import {Grid, printContent, showErrorNotify, showSuccessNotify} from '../components'
import PaymentIcon from '@material-ui/icons/Payment';
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import env from "../env";
import NumberFormat from 'react-number-format';
import './popup.css';
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import Table from '@material-ui/core/Table';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import TableBody from '@material-ui/core/TableBody';
import {PaymentMethodInput} from "./PaymentMethod";
import axiosInstance from "../api/axios";
import BillDetailDialog from "./BillDetailDialog";
import QrInfoDialog from "./QrInfoDialog";

const styles = {
    suggestDialog: {
        zIndex: '1 !important',
    },
    width100: {
        width: '100%',
    }
};

class PostQuickPayBillButton extends Component {
    state = {
        isLoading: false,
        showDialog: false,
        showDialogDetail: false,
        showDialogQr: false,
        showBtn: true,
        disableInput: false,
        totalPayment: 0,
        fullDay: true,
        enableElectricBill: false,
        enableShowPrint: true,
        enableShowQr: false,
        payRoundBill: false,
        showGateway: false,
        totalCanPayAmount: 0,
        monthChoices: [],
        gateways: [],
        billDetail: {},
        qrInfo: null
    };

    params = {
        customerId: 0,
        clazzName: '',
        relationshipName: '',
        relationshipAddress: '',
        gateway: '',
        paymentDate: '',
        totalPayment: 0,
        bills: [],
        remainPaymentAmount: 0,
        numOfMonth: 1
    };

    constructor(props) {
        super(props);
    }

    handleClick = () => {
        this.setState({
            showDialog: true,
            customerId: null
        });
        const record = this.props.record;
        if (record) {
            this.params.customerId = record.customerId;
            this.state.disableInput = true;
        } else {
            this.params.customerId = null;
            this.state.disableInput = false;
        }
        this.processChangePaymentInfo();
    };

    changePaymentInfo = (e, customerId) => {
        this.params.customerId = customerId;
        this.processChangePaymentInfo();
    };

    processChangePaymentInfo() {
        if (this.params.customerId) {
            this.getPaymentInfo().then(({data}) => {
                this.params = data;
                const method = data.paymentMethod ? data.paymentMethod : 'BANK_TRANSFER';
                this.params.paymentMethod = method
                this.params.numOfMonth = 1;
                this.params.gateway = '';
                this.params.paymentDate = '';
                this.setState({
                    totalPayment: data.totalPayment,
                    maxPaymentAmount: data.totalPayment,
                    remainPaymentAmount: data.remainPaymentAmount,
                    enableElectricBill: data.enableElectricBill,
                    enableShowPrint: data.enableShowPrint,
                    enableShowQr: data.enableShowQr,
                    payRoundBill: data.payRoundBill,
                    bills: data.bills,
                    availableBills: data.availableBills,
                    totalCanPayAmount: data.totalCanPayAmount,
                    paymentMethod: method,
                    numOfMonth: 1,
                    fullDay: data.fullDay,
                    gateways: data.gateways,
                    showGateway: method === 'BANK_TRANSFER'
                });
                this.changeNumOfMonth(1);
            });

        }
    }

    getPaymentInfo() {
        const url = `/api/payments/getPaymentInfo?customerId=` + this.params.customerId;
        return axiosInstance.get(url);
    }

    handleCloseClick = () => {
        this.state.totalPayment = 0;
        this.params = {}
        this.setState({
            showDialog: false,
            disableInput: true,
            bills: [],
            totalPayment: 0
        })
    };

    renderTable() {
        let table = [];
        if (this.state.bills && this.state.bills.length > 0) {
            this.state.monthChoices = [];
            table.push(<Table><TableBody><TableRow><TableCell colSpan={3}>Chi tiết thanh
                toán</TableCell></TableRow>{this.renderTableRow()}</TableBody></Table>)

        } else if (this.state.remainPaymentAmount) {
            table.push(<span>Số tiền thừa: {this.formatNumber(this.state.remainPaymentAmount)} <br/> Hiện chưa có phiếu học phí cần thanh toán</span>);
        } else if (this.params.customerId) {
            table.push(<span>Hiện chưa có phiếu học phí cần thanh toán</span>);
        }

        return table;
    }

    renderTableRow() {
        let total = 0;
        let table = []
        for (let i = 0; i < this.state.bills.length; i++) {
            this.state.monthChoices.push({
                id: i + 1,
                name: i + 1
            });
            const bill = this.state.bills[i];
            total += bill.billBalance;
            table.push(<TableRow key={i}><TableCell align="right">{bill.billNo}</TableCell><TableCell
                align="right">{bill.billMonth}</TableCell><TableCell
                align="right">{this.formatNumber(bill.billBalance)}</TableCell></TableRow>);
        }
        // table.push(<TableRow><TableCell colSpan={3}>Tổng số tiền cần thanh toán: {this.formatNumber(total)}</TableCell></TableRow>)
        // table.push(<TableRow><TableCell colSpan={3}>Tổng số tiền có thể thanh toán: {this.formatNumber(this.state.totalCanPayAmount)}</TableCell></TableRow>)
        return table;
    }

    formatNumber(num) {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
    }

    handleSaveClick = () => {
        const {submit} = this.props;

        // Trigger a submit of our custom quick create form
        // This is needed because our modal action buttons are oustide the form
        submit('payment-quick-create');
    };

    handleSubmit = () => {
        let numOfMonth = this.state.numOfMonth;
        if (numOfMonth <= 0 || this.state.availableBills.length < numOfMonth) {
            showErrorNotify('Vui lòng kiểm tra lại số tháng thanh toán.');
            return;
        }
        if (!this.state.totalPayment || this.state.totalPayment <= 0) {
            showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
            return;
        }
        if (this.state.totalPayment > this.state.totalCanPayAmount) {
            showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
            return;
        }

        this.setState({
            isLoading: true
        })

        const {fetchStart, fetchEnd} = this.props;
        fetchStart();

        this.sendPayment()
            .then(({data}) => {
                if (data == null || !data.paymentId) {
                    showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
                    this.setState({
                        isLoading: false
                    })
                } else {
                    showSuccessNotify('Thanh toán học phí thành công!')
                    this.handleCloseClick();
                    this.props.refreshView();
                    setTimeout(() => {
                        if (this.state.enableShowPrint) {
                            printContent(this.getPrintElectronicBillUrl(data.paymentId))
                        } else {
                            const url = `/api/paymentViews/print-both-receipt?` +
                                `paymentId=${data.paymentId}`;
                            axiosInstance.get(url)
                        }
                    }, 1000)
                }
            })
            .catch(error => {
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                this.setState({
                    isLoading: false
                })
            })
            .finally(() => {
                fetchEnd();
            });
    };

    getPrintElectronicBillUrl(paymentId) {
        return `${env.baseURL}/api/paymentViews/print-both-receipt?` +
            `paymentId=${paymentId}`;
    };

    validate = (values) => {
        const errors = {};
        // Validate
        if (!values.customerId) {
            errors.customerId = 'Vui lòng nhập dữ liệu'
        }
        if (!values.payerName) {
            errors.payerName = 'Vui lòng nhập dữ liệu'
        }
        // Copy input values to params
        this.params = values;
        return errors;
    };

    sendPayment() {
        const url = `/api/payments/manual`;
        const values = {};
        values['customerId'] = this.params.customerId;
        values['paymentAmount'] = this.params.totalPayment;
        values['payerName'] = this.params.relationshipName;
        values['payerAddress'] = this.params.relationshipAddress;
        values['paymentMethod'] = this.params.paymentMethod;
        values['paymentDate'] = this.params.paymentDate;
        values['gateway'] = this.params.gateway;
        return axiosInstance.post(url, values);
    }

    changePaymentAmount(e) {
        this.params.totalPayment = e.floatValue
        this.setState({totalPayment: e.floatValue})
    }

    changeNumOfMonth(numOfMonth) {
        if (!this.state.availableBills || this.state.availableBills.length === 0) {
            return;
        }
        if (numOfMonth > this.state.availableBills.length) {
            showErrorNotify('Vui lòng kiểm tra lại số tháng thanh toán.');
            return;
        }
        let paymentAmount = 0;
        for (let i = 0; i < numOfMonth; i++) {
            const bill = this.state.availableBills[i];
            paymentAmount += bill.billBalance;
        }

        this.params.totalPayment = paymentAmount;
        this.setState({totalPayment: paymentAmount})
        this.forceUpdate();
    }

    changePaymentMethod(method) {
        if ('BANK_TRANSFER' === method) {
            this.setState({showGateway: true, paymentMethod: method})
        } else {
            this.setState({showGateway: false, paymentMethod: method})
        }

    }

    getPaymentDetail() {
        const url = `/api/payments/getPaymentDetail?customerId=${this.params.customerId}&numOfMonth=${this.params.numOfMonth}`;
        return axiosInstance.get(url);
    }
    doGroupAndPrintQr() {
        const url = `/api/payments/groupBill?customerId=${this.params.customerId}&numOfMonth=${this.params.numOfMonth}`;
        return axiosInstance.get(url);
    }

    getPaymentConfirmUrl(paymentId) {
        return `${env.baseURL}/api/paymentViews/print-confirm?` +
            `paymentId=${paymentId}`;
    };

    handlePaymentAndPrintConfirmClick = () => {
        let numOfMonth = this.state.numOfMonth;
        if (numOfMonth <= 0 || this.state.availableBills.length < numOfMonth) {
            showErrorNotify('Vui lòng kiểm tra lại số tháng thanh toán.');
            return;
        }
        if (!this.state.totalPayment || this.state.totalPayment <= 0) {
            showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
            return;
        }
        if (this.state.totalPayment > this.state.totalCanPayAmount) {
            showErrorNotify('Vui lòng kiểm tra lại số tiền thanh toán');
            return;
        }
        this.setState({
            isLoading: true
        })
        const {fetchStart, fetchEnd} = this.props;
        fetchStart();

        this.sendPayment()
            .then(({data}) => {
                if (data == null || !data.paymentId) {
                    showErrorNotify('Thanh toán không thành công, vui lòng kiểm tra lại số tiền hoặc liên hệ quản trị viên!')
                    this.setState({
                        isLoading: false
                    })
                } else {
                    showSuccessNotify('Thanh toán học phí thành công!')
                    this.handleCloseClick();
                    this.props.refreshView();
                    const url = `/api/paymentViews/print-both-receipt?` +
                        `paymentId=${data.paymentId}`;
                    axiosInstance.get(url);
                    setTimeout(() => {
                        printContent(this.getPaymentConfirmUrl(data.paymentId))
                    }, 1000)
                }
            })
            .catch(error => {
                showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
                this.setState({
                    isLoading: false
                })
            })
            .finally(() => {
                fetchEnd();
            });
    };
    handleViewDetailClick = () => {
        this.getPaymentDetail().then(({data}) => {
            this.setState({
                billDetail: data,
                showDialogDetail: true,
                showDialog: false
            });
        });
    };
    handleGroupAndQrClick = () => {
        this.doGroupAndPrintQr().then(({data}) => {
            if (data) {
                this.setState({
                    qrInfo: data,
                    showDialogQr: true,
                    showDialog: false
                });
            }
        });
    };
    handleCloseDialogDetail = () => {
        this.setState({
            billDetail: {},
            showDialogDetail: false,
            showDialog: true
        })
    };
    handleCloseDialogQr = () => {
        this.setState({
            showDialogQr: false,
            showDialog: true,
            qrInfo: {},
        })
    };

    render() {
        const {isSubmitting, classes} = this.props;
        const {qrInfo, showDialogQr, showDialogDetail, showDialog, enableShowQr, isLoading, billDetail} = this.state;

        return (
            <Fragment>
                {/*buton in row*/}
                {this.state.disableInput && <Button onClick={this.handleClick} label="ra.action.pay">
                    <PaymentIcon/>
                </Button>}
                {/*buton on top list*/}
                {!this.state.disableInput &&
                <Button variant="contained" color="primary" onClick={this.handleClick} label="ra.action.pay">
                    <PaymentIcon/>
                </Button>}


                <Dialog
                    className={classes.suggestDialog}
                    fullWidth={true}
                    disableBackdropClick={true}
                    maxWidth='md'
                    open={showDialog}
                    onClose={this.handleCloseClick}
                    aria-label="Thanh toán"
                >
                    <DialogTitle>Thanh toán khoản thu</DialogTitle>
                    <DialogContent>
                        <SimpleForm
                            record={this.params}
                            form="payment-quick-create"
                            onSubmit={this.handleSubmit}
                            toolbar={null}
                            validate={this.validate}
                        >
                            <Grid container spacing={0} className={classes.width100}>
                                <Grid item xs={12} sm={5}>
                                    {this.state.disableInput ?
                                        <TextInput source="customerId" disabled={true}
                                                   label="resources.billViews.fields.customerId"
                                                   validate={required()}/> :
                                        <ReferenceInput label="resources.billViews.fields.customerId"
                                                        source="customerId" reference="customers"
                                                        onChange={(event, customerId) => this.changePaymentInfo(event, customerId)}>
                                            <AutocompleteInput optionText="id"/>
                                        </ReferenceInput>
                                    }
                                    {this.state.disableInput ?
                                        <TextInput source="customerName" disabled={true}
                                                   label="resources.billViews.fields.customerName"
                                                   validate={required()}/> :
                                        <ReferenceInput label="resources.billViews.fields.customerName"
                                                        source="customerId" reference="customers"
                                                        onChange={(event, customerId) => this.changePaymentInfo(event, customerId)}>
                                            <AutocompleteInput optionText="name"/>
                                        </ReferenceInput>
                                    }

                                    <TextInput source="relationshipName" disabled={true}
                                               label="resources.billViews.fields.payerName" validate={required()}/>
                                    <TextInput source="relationshipAddress"
                                               label="resources.billViews.fields.payerAddress"/>

                                </Grid>
                                <Grid item xs={12} sm={1}>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    {this.renderTable()}

                                    {this.state.bills && this.state.bills.length > 0 ?
                                        <Grid item xs={12} sm={6}>
                                            <SelectInput source="numOfMonth"
                                                         onChange={(e, v) => this.changeNumOfMonth(v)}
                                                         label="resources.billViews.fields.numOfMonth"
                                                         choices={this.state.monthChoices}/>
                                        </Grid> : ''
                                    }
                                    {this.state.bills && this.state.bills.length > 0 ?
                                        <Grid item xs={12} sm={6}>
                                            <PaymentMethodInput source="paymentMethod"
                                                                onChange={(e, v) => this.changePaymentMethod(v)}/>
                                        </Grid> : ''
                                    }
                                    {this.state.showGateway ?
                                        <Grid item xs={12} sm={6}>
                                            <SelectInput source='gateway' choices={this.state.gateways}
                                                         label="resources.billViews.fields.gateway"/>
                                        </Grid>
                                        : ''
                                    }
                                    {this.state.showGateway ?
                                        <Grid item xs={12} sm={6}>
                                            <DateInput label="resources.billViews.fields.paymentDate"
                                                       source="paymentDate"/>
                                        </Grid>
                                        : ''
                                    }
                                    {this.state.bills && this.state.bills.length > 0 ?
                                        <NumberFormat thousandSeparator={true} allowNegative={false}
                                                      customInput={TextField}
                                                      value={this.state.totalPayment}
                                                      label="Số tiền thanh toán"
                                                      required={true} disabled={true}/> : ''
                                    }
                                </Grid>

                            </Grid>
                        </SimpleForm>
                    </DialogContent>
                    <DialogActions>
                        {enableShowQr &&
                            <Button disabled={isLoading} variant={'outlined'}
                                    label="resources.billViews.groupAndPrintQr"
                                    onClick={this.handleGroupAndQrClick}>
                            </Button>
                        }
                        <Button disabled={isLoading} variant={'contained'} label="ra.action.viewDetail"
                                onClick={this.handleViewDetailClick}>
                        </Button>
                        <Button disabled={isLoading} variant={'contained'} label="ra.action.payAndPrintConfirm"
                                onClick={this.handlePaymentAndPrintConfirmClick}>
                        </Button>
                        <SaveButton
                            disabled={isLoading}
                            label="ra.action.pay"
                            saving={isSubmitting}
                            onClick={this.handleSaveClick}
                        />
                        <Button label="ra.action.cancel" onClick={this.handleCloseClick}>
                            <IconCancel/>
                        </Button>
                    </DialogActions>
                </Dialog>

                <BillDetailDialog showDialogDetail={showDialogDetail} billDetail={billDetail}
                                  handleCloseDialogDetail={this.handleCloseDialogDetail}/>
                <QrInfoDialog show={showDialogQr} qrInfo={qrInfo}
                              handleCloseDialog={this.handleCloseDialogQr}/>


            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    isSubmitting: isSubmitting('payment-quick-create')(state)
});

const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView
};

export default compose(connect(mapStateToProps, mapDispatchToProps), withStyles(styles))(
    PostQuickPayBillButton
);
