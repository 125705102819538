import React from 'react';
import {Redirect} from 'react-router-dom';
import {connect} from 'react-redux';
import {Card, CardContent, CircularProgress, Grid, TextField} from '@material-ui/core'
import {LongTextInput, required, SimpleForm, Title} from 'react-admin';
import RoleCustomToolbar from "../Role/RoleCustomToolbar";
import axiosInstance from "../../api/axios";
import {
    closeNotify,
    FileUploadComponent,
    showErrorNotify,
    showInfoNotify,
    showSuccessNotify,
    showWarningNotify
} from "../../components";
import env from "../../env";
import axios from "axios";

export class MessageBatchCreate extends React.Component {

    state = {
        title: 'Thông báo',
        description: '',
        enable: true,
        isRedirect: false,
        file: null
    }

    constructor(props) {
        super(props);
        this.state = {
            description: '',
            title: 'Thông báo',
            showValidateBtn: false,
            validationData: [],
            isLoading: false,
            showUploadFile: true,
        };
        this.onFileUpload = this.onFileUpload.bind(this);
        this.setShowData = this.setShowData.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }

    changeDescription = (val) => {
        this.setState({description: val});
    }

    changeTitle = (val) => {
        this.setState({title: val});
    }

    changeFile = (e) => {
        this.setState({
            file: e.target.files[0]
        })
    }

    onFileUpload(e) {
        e.preventDefault(); // Stop form submit
        if (!this.validate()) {
            return;
        }
        if (this.state.file == null) {
            return;
        }

        this.setState({
            isLoading: true
        });
        showInfoNotify("Đang xử lí file, vui lòng chờ trong giây lát!");
        // lưu lô thực đơn
        const {description, title} = this.state;
        const batchData = {
            description,
            title
        }
        const url = `/api/messageBatches`;
        return axiosInstance.post(url, batchData).then(rs => {
            const batchId = rs.data.id
            this.setState({
                batchId: batchId
            })
            const formData = new FormData();
            formData.append('file', this.state.file);
            formData.append('batchId', batchId);
            showInfoNotify("Đang xử lý file, vui lòng chờ trong giây lát!")
            axios({
                url: `${env.baseURL}/api/messageBatchUpload/validate-item`,
                method: 'POST',
                data: formData,
                timeout: 600000,
                responseType: 'blob', // important
                headers: {
                    'content-type': 'multipart/form-data',
                    Authorization: localStorage.getItem('token')
                }
            }).then((res) => {
                closeNotify();
                if (res.data && res.data.size > 0) {
                    let fileNameFromSv = res.headers['attachmentfilename'];
                    let blob = new Blob([res.data]);
                    let link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = fileNameFromSv ? fileNameFromSv : 'kq_lo_tin_nhan.xlsx';
                    link.click();
                    showSuccessNotify("Kiểm tra kết quả trong file.");
                    this.setState({
                        isRedirect: true
                    });
                } else {
                    this.setState({
                        isLoading: false,
                    })
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                }
            })
                .catch(error => {
                    showErrorNotify("Xử lí file bị lỗi. Vui lòng kiểm tra lại file hoặc liên hệ quản trị viên!");
                    this.setState({
                        isLoading: false,
                    })
                });
        }).catch(err => {
            this.setState({
                isLoading: false,
            })
            showErrorNotify(err.message);
        })
    }

    handleChangePage(event, newPage) {
        const startIdx = newPage * this.state.rowsPerPage;
        const endIdx = (newPage + 1) * this.state.rowsPerPage;
        this.setShowData(startIdx, endIdx);
        this.setState({page: newPage});
    }

    handleChangeRowsPerPage(event) {
        this.setState({rowsPerPage: event.target.value});
        const startIdx = this.state.page * event.target.value;
        const endIdx = (this.state.page + 1) * event.target.value;
        this.setShowData(startIdx, endIdx);
    }

    setShowData(startIdx, endIdx) {
        const data = [];
        let filteredData = this.state.validationData;
        if (endIdx > filteredData.length) {
            endIdx = filteredData.length;
        }
        for (let i = startIdx; i < endIdx; i++) {
            data.push(filteredData[i]);
        }

        this.setState({showData: data});
    }

    save = () => {
        if (!this.validate()) {
            return;
        }
        const {description, title} = this.state;
        const data = {
            description,
            title
        }

        const url = `/api/messageBatches`;
        return axiosInstance.post(url, data).then(rs => {
            this.cancel();
        }, err => {
            this.setState({
                isLoading: false,
            })
            showWarningNotify(err.message);
        });
    };

    cancel = (e) => {
        this.setState({
            isRedirect: true
        })
    };

    validate = () => {
        const {description} = this.state;
        if (!description) {
            showWarningNotify('Vui lòng nhâp nội dung');
            return false;
        }
        return true;
    }

    render() {
        if (this.state.isRedirect) {
            return <Redirect to='/messageBatches'/>;
        }
        const {description, title} = this.state;
        return (
            <React.Fragment>
                <Card>
                    <Title title="Tạo lô tin nhắn"/>
                    <CardContent>
                        <SimpleForm toolbar={<RoleCustomToolbar save={this.save} cancel={this.cancel}/>}
                                    redirect="list">
                            <Grid container spacing={8}>
                                <Grid item xs={12} sm={6}>
                                    <TextField style={{width: '90%'}}
                                               id="standard-required"
                                               label="Tiêu đề"
                                               margin="normal"
                                               value={title}
                                               onChange={(e) => this.changeTitle(e.target.value)}
                                    />
                                </Grid>
                                <LongTextInput
                                    inputProps={{maxLength: 5000}}
                                    label="resources.messages.fields.text"
                                    source="description" validate={required()} rows={10}
                                    value={description}
                                    onChange={(e) => this.changeDescription(e.target.value)}/>

                                <Grid container spacing={8}>
                                    {
                                        this.state.showUploadFile && <Grid item xs={12} sm={6}>
                                            <FileUploadComponent
                                                accept={".zip"}
                                                onFileChanged={(e) => this.setState({file: e.target.files[0]})}
                                                onFileUpload={this.onFileUpload}
                                            />
                                            {
                                                this.state.isLoading && <CircularProgress color="secondary"/>
                                            }
                                        </Grid>
                                    }

                                </Grid>
                            </Grid>
                        </SimpleForm>
                    </CardContent>
                </Card>
            </React.Fragment>
        )
    }
}

export default connect(null, null)(MessageBatchCreate);
