import {stringify} from 'query-string';
import {
    CREATE,
    DELETE,
    DELETE_MANY,
    fetchUtils,
    GET_LIST,
    GET_MANY,
    GET_MANY_REFERENCE,
    GET_ONE,
    UPDATE,
    UPDATE_MANY,
} from 'react-admin';
import env from "../env/env";

const authHttpClient = (url, options = {}) => {
    if (!options.headers) {
        options.headers = new Headers({Accept: 'application/json'});
        // options.headers = new Headers({ Accept: 'application/hal+json' });
    }
    const token = localStorage.getItem('token');
    options.headers.set('Authorization', token);
    return fetchUtils.fetchJson(url, options);
}

function isEmpty(obj) {
    return (Object.keys(obj).length === 0) && (obj.constructor === Object);
}


const kiotResourcePaths = ['kiotConfigs', 'kiotProducts', 'kiotProductMenus', 'kiotProductMenuBatchUpload', 'kiotProductMenuBatches', 'kiotImportOrders', 'kiotOrders', 'kiotDeliveredOrders']
const reportResourcePaths = ['student-relationships']

/**
 * Maps react-admin queries to a json-server powered REST API
 *
 * @example
 * GET_LIST     => GET http://my.api.url/posts?page=0&size=10&sort=id,desc
 * GET_ONE      => GET http://my.api.url/posts/123
 * GET_MANY     => GET http://my.api.url/posts/123, GET http://my.api.url/posts/456, GET http://my.api.url/posts/789
 * UPDATE       => PUT http://my.api.url/posts/123
 * CREATE       => POST http://my.api.url/posts/123
 * DELETE       => DELETE http://my.api.url/posts/123
 */
export default (apiUrl, httpClient = authHttpClient) => {
    /**
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     *
     * @returns {Object} { url, options } The HTTP request parameters
     */
    const convertDataRequestToHTTP = (type, resource, params) => {
        let url = '';
        const options = {};
        let finalApiUrl = apiUrl
        if (kiotResourcePaths.includes(resource)) {
            finalApiUrl = env.kiotUrl + '/api'
        } else if (reportResourcePaths.includes(resource)) {
            finalApiUrl = env.reportURL + '/api'
        }
        switch (type) {
            case GET_LIST: {
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const filters = fetchUtils.flattenObject(params.filter);
                const query = {
                    ...filters,
                    size: perPage,
                    page: page - 1,
                    sort: field + ',' + order,
                };
                url = `${finalApiUrl}/${resource}?${stringify(query)}`;
                if (!isEmpty(filters)) {
                    if (filters.filterPath) {
                        url = `${finalApiUrl}/${resource}/search/` + filters.filterPath + `?${stringify(query)}`;
                    } else {
                        url = `${finalApiUrl}/${resource}/search/filter?${stringify(query)}`;
                    }

                }
                url += '&' + (params.others || '')
                break;
            }
            case GET_ONE:
                url = `${finalApiUrl}/${resource}/${params.id}`;
                break;
            case GET_MANY_REFERENCE: {
                // console.log('GET_MANY_REFERENCE', params)   // TODO: Check again
                const {page, perPage} = params.pagination;
                const {field, order} = params.sort;
                const target = params.target.substring(0, 1).toUpperCase() + params.target.substring(1)
                const query = {
                    ...fetchUtils.flattenObject(params.filter),
                    [params.target]: params.id,
                    size: perPage,
                    page: page - 1,
                    sort: field + ',' + order,
                };
                url = `${finalApiUrl}/${resource}?${stringify(query)}`;
                url = `${finalApiUrl}/${resource}/search/findBy${target}?${stringify(query)}`;
                break;
            }
            case UPDATE:
                url = `${finalApiUrl}/${resource}/${params.id}`;
                options.method = 'PUT';
                options.body = JSON.stringify(params.data);
                break;
            case CREATE:
                url = `${finalApiUrl}/${resource}`;
                options.method = 'POST';
                options.body = JSON.stringify(params.data);
                break;
            case DELETE:
                url = `${finalApiUrl}/${resource}/${params.id}`;
                options.method = 'DELETE';
                break;
            case GET_MANY: {
                const arr = [];
                for (let i = 0; i < params.ids.length; i++) {
                    const id = params.ids[i];
                    if (Array.isArray(id)) {
                        for (let j = 0; j < id.length; j++) {
                            arr.push((id[j] + '').split('_')[0])
                        }
                    } else {
                        arr.push(id)
                    }
                }
                const query = {
                    [`ids`]: arr.join(','),
                };
                url = `${finalApiUrl}/${resource}/search/findByIdIn?${stringify(query)}`;
                break;
            }
            default:
                throw new Error(`Unsupported fetch action type ${type}`);
        }
        return {url, options};
    };

    const handlingError = (err) => {
        const svMessage = err.message
        if (svMessage) {
            switch (err.message) {
                case 'MCP0013':
                    err.message = 'Không có quyền đọc dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                    break;
                case 'MCP0014':
                    err.message = 'Không có quyền tạo dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                    break;
                case 'MCP0015':
                    err.message = 'Không có quyền sửa dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                    break;
                case 'MCP0016':
                    err.message = 'Không có xóa sửa dữ liệu, liên hệ 1900 27 27 28 để được hỗ trợ';
                    break;
                case 'MCP0017':
                    err.message = 'Thiếu quyền truy cập api, liên hệ quản lí để được hỗ trợ';
                    break;
                case 'MCP0018':
                    err.message = 'Tồn tại dữ liệu liên quan, liên hệ 1900 27 27 28 để được hỗ trợ';
                    break;
                case 'MCP0027':
                    err.message = 'Dữ liệu đã được chốt, liên hệ 1900 27 27 28 để được hỗ trợ chỉnh sửa';
                    break;
                default:
                    break
            }
        } else if (err.status) {
            switch (err.status) {
                case 429:
                    err.message = "Lượng truy cập của bạn vào hệ thống vượt quá giới hạn cho phép. "
                        + "Vui lòng chờ sau giây lát rồi truy cập lại. Xin cảm ơn.";
                    break;
                case 401:
                    err.message = "Thông tin đăng nhập không đúng hoặc không tồn tại, vui lòng đăng nhập lại";
                    break;
                case 404:
                    err.message = "Không thể kết nối đến hệ thống";
                    break;
                case 403:
                    err.message = "Truy cập đến hệ thống bị cấm, liên hệ 1900 27 27 28 để được hỗ trợ";
                    break;
                case 400:
                    err.message = "Truy cập hệ thống không hợp lệ, liên hệ 1900 27 27 28 để được hỗ trợ";
                    break;
                default:
                    err.message = "Xuất hiện lỗi từ hệ thống, liên hệ 1900 27 27 28 để được hỗ trợ";
                    break;
            }
        } else {
            err.message = "Xuất hiện lỗi từ hệ thống, liên hệ 1900 27 27 28 để được hỗ trợ";
        }

        return Promise.reject(err);
    }

    /**
     * @param {Object} response HTTP response from fetch()
     * @param {String} type One of the constants appearing at the top if this file, e.g. 'UPDATE'
     * @param {String} resource Name of the resource to fetch, e.g. 'posts'
     * @param {Object} params The data request params, depending on the type
     * @returns {Object} Data response
     */
    const convertHTTPResponse = (response, type, resource, params) => {
        // console.log('convertHTTPResponse', type, json, resource, params)

        const {json} = response;
        // Remove 'links' and 'content' fields
        if (json && Array.isArray(json.content)) {
            for (var i = 0; i < json.content.length; i++) {
                let item = json.content[i]
                delete item.links
                delete item.content
            }
        }

        switch (type) {
            case GET_ONE:
                return {data: json};
            case GET_LIST:
            case GET_MANY_REFERENCE:
                if (json.page && json.page.totalElements === 0) {     // TODO: Spring Data Rest issue
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return {
                    data: json.content,
                    total: json.page ? json.page.totalElements : json.content.length,
                };
            case GET_MANY:
                if (json.content[0].value) {     // TODO: Spring Data Rest issue
                    return {
                        data: [],
                        total: 0,
                    };
                }
                return {
                    data: json.content,
                    total: json.content.length,
                };
            case CREATE:
                return {data: {...params.data, id: json.id}};
            case UPDATE:
                return {data: json}
            case DELETE:
                return {data: {...params.previousData}};
            default:
                throw new Error(`Convert HTTPResponse: Unsupported fetch action type ${type}`);
            // return { data: json };
        }
    };

    /**
     * @param {string} type Request type, e.g GET_LIST
     * @param {string} resource Resource name, e.g. "posts"
     * @param {Object} payload Request parameters. Depends on the request type
     * @returns {Promise} the Promise for a data response
     */
    return (type, resource, params) => {
        let finalApiUrl = apiUrl
        if (kiotResourcePaths.includes(resource)) {
            finalApiUrl = env.kiotUrl + '/api'
        }
        if (type === UPDATE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${finalApiUrl}/${resource}/${id}`, {
                        method: 'PATCH',
                        body: JSON.stringify(params.data),
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            })).catch(err => {
                return handlingError(err);
            });
        }

        if (type === DELETE_MANY) {
            return Promise.all(
                params.ids.map(id =>
                    httpClient(`${finalApiUrl}/${resource}/${id}`, {
                        method: 'DELETE',
                    })
                )
            ).then(responses => ({
                data: responses.map(response => response.json),
            })).catch(err => {
                return handlingError(err);
            });
        }

        // GET_xxx
        const {url, options} = convertDataRequestToHTTP(
            type,
            resource,
            params
        );
        return httpClient(url, options).then(response => {
            const rs = convertHTTPResponse(response, type, resource, params)
            if (rs && rs.data && rs.data.error) {
                return handlingError(rs.data.error);
            }
            return rs;
        }).catch(err => {
            return handlingError(err);
        });
    };

};
