import React from 'react';
import {BooleanField, Datagrid, Filter, List, SelectInput, TextField, TextInput} from 'react-admin';
import {DateTimeField, schoolYears} from "../../components";

const SemesterFilter = (props) => (
    <Filter {...props}>
        <SelectInput label="resources.schoolYears.name" source="schoolYearId" choices={schoolYears}
                     alwaysOn allowEmpty={false}
        />
        <TextInput label="ra.action.search" source="q" alwaysOn/>
    </Filter>
);

export const SemesterList = props => (
    <List filters={<SemesterFilter/>}
          filterDefaultValues={{schoolYearId: (schoolYears && schoolYears.length > 0) ? schoolYears[0].id : null}} {...props}
          exporter={false}>
        <Datagrid rowClick="edit">
            <TextField source="id"/>
            <TextField source="name"/>
            <DateTimeField source="startDate" format="DD/MM/YYYY"/>
            <DateTimeField source="endDate" format="DD/MM/YYYY"/>
            <TextField source="displayOrder"/>
            <BooleanField source="useDefault"/>
        </Datagrid>
    </List>
);

export default SemesterList
