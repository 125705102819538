import React, {useEffect, useRef, useState} from 'react';
import {SaveButton, Title, translate} from "react-admin";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    LinearProgress,
    List,
    ListItem,
    NativeSelect,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField
} from '@material-ui/core';
import axiosInstance from "../../api/axios";
import moment from "moment";
import {CancelButton, showErrorNotify} from "../../components";


const Filter = translate(({translate, filterParams = {}, onChangeFilter}) => {
    const [units, setUnits] = useState([])

    useEffect(() => {
        axiosInstance.get(`/api/customer-discipline/units`).then(rs => {
            const units = rs.data
            setUnits(units)
            onChangeFilter('unitId', units?.[0]?.id)
        })
    }, [])

    return <Grid container spacing={24} alignItems={'flex-end'}>
        <Grid item>
            <FormControl>
                <InputLabel shrink>{translate("resources.customer-discipline.fields.units")}</InputLabel>
                <NativeSelect name="units"
                              value={filterParams['units']}
                              onChange={e => onChangeFilter('unitId', e.target.value)}
                >
                    {units.map(unit => <option key={unit.id} value={unit.id}>{unit.name}</option>)}
                </NativeSelect>
            </FormControl>
        </Grid>
        <Grid item>
            <TextField type={'date'}
                       label={translate("resources.customer-discipline.fields.checkDate")}
                       value={filterParams['checkDate']}
                       onChange={e => onChangeFilter('checkDate', e.target.value)}
                       inputProps={{max: moment().format('YYYY-MM-DD')}}
            />
        </Grid>
    </Grid>
})

const UpdateCustomerDisciplineDialog = translate(({open, onClose, onSave, saving, data = {}}) => {
    const ref = useRef([...data.disciplineCategories])

    const onChangeValue = (field, index) => (event) => {
        ref.current[index][field] = event.target.value
    }

    return <React.Fragment>
        <Dialog open={open} onClose={onClose} maxWidth={'md'} fullWidth disableBackdropClick disableEscapeKeyDown>
            <DialogTitle>
                {data.customerId} - {data.customerName}
            </DialogTitle>
            <DialogContent style={{padding: 0}}>
                <List component="nav">
                    {
                        data.disciplineCategories.map((item, idx) => <ListItem key={item.id}>
                            <Grid container spacing={24}>
                                <Grid item xs={'auto'}>
                                    <TextField disabled
                                               label={'Nội dung'}
                                               InputLabelProps={{shrink: true}}
                                               defaultValue={item.name}
                                               style={{width: 300}}
                                    />
                                </Grid>
                                <Grid item xs='auto'>
                                    <TextField label='Số lượt'
                                               type='number'
                                               inputProps={{min: 0}}
                                               style={{width: 80}}
                                               InputLabelProps={{shrink: true}}
                                               defaultValue={item.count}
                                               onChange={onChangeValue('count', idx)}
                                    />
                                </Grid>

                                <Grid item xs>
                                    <TextField fullWidth
                                               label='Ghi chú'
                                               InputLabelProps={{shrink: true}}
                                               defaultValue={item.note}
                                               onChange={onChangeValue('note', idx)}
                                    />
                                </Grid>
                            </Grid>
                        </ListItem>)
                    }
                </List>
            </DialogContent>
            <DialogActions>
                <CancelButton onClick={onClose}/>
                <SaveButton saving={saving} disabled={saving} onClick={() => onSave(data.customerId, ref.current)}/>
            </DialogActions>
        </Dialog>

    </React.Fragment>
})

const DataTable = translate(({translate, filterParams = {}, permissions = []}) => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const {q, unitId, checkDate} = filterParams
    const [saving, setSaving] = useState(false)
    const [selectedRow, setSelectedRow] = useState()


    const onOpenDialog = (row) => {
        setSelectedRow(row)
    }

    const onClose = () => {
        setSelectedRow(undefined)
    }

    const onSave = async (customerId, disciplineCategories = []) => {
        setSaving(true)
        try {
            const payload = {customerId, unitId, checkDate, disciplineCategories}
            await axiosInstance.post(`/api/customer-discipline`, payload)
            setData(current => current.map(
                item => item.customerId === customerId
                    ? {...item, disciplineCategories}
                    : item
            ))
        } catch (e) {
            showErrorNotify("Có lỗi xảy ra. Vui lòng liên hệ quản trị viên!")
        }
        setSaving(false)
        setSelectedRow(undefined)
    }

    useEffect(() => {
        if (!filterParams.unitId)
            return

        setLoading(true)
        const params = new URLSearchParams(filterParams)
        axiosInstance.get(`/api/customer-discipline?${params.toString()}`)
            .then(rs => setData(rs.data))
            .finally(() => setLoading(false))
    }, [q, unitId, checkDate]);

    const editable = permissions?.includes('EDITABLE_PAST_DISCIPLINE') || moment().isSame(moment(checkDate), 'days')
    return <div style={{marginTop: 16}}>
        {
            !!selectedRow && <UpdateCustomerDisciplineDialog open={!!selectedRow} onClose={onClose}
                                                             onSave={onSave}
                                                             saving={saving}
                                                             data={selectedRow}
                                                             checkDate={checkDate}
            />
        }

        {loading && <LinearProgress/>}
        <Table>
            <TableHead>
                <TableRow style={{backgroundColor: "#f5f5f5"}}>
                    <TableCell width={30}>{'STT'}</TableCell>
                    <TableCell width={70}>{translate("resources.customer-discipline.fields.customerId")}</TableCell>
                    <TableCell width={150}>{translate("resources.customer-discipline.fields.customerName")}</TableCell>
                    <TableCell>{translate("resources.customer-discipline.fields.description")}</TableCell>
                    <TableCell width={100}></TableCell>
                </TableRow>
            </TableHead>
        </Table>

        <div style={{overflow: 'auto', maxHeight: window.innerHeight * 0.7, height: window.innerHeight * 0.7}}>
            <Table>
                <TableBody>
                    {data.map((row, index) => (
                        <TableRow key={row.customerId}>
                            <TableCell width={30}>
                                {index + 1}
                            </TableCell>
                            <TableCell width={70}>
                                {row.customerId}
                            </TableCell>
                            <TableCell width={150}>
                                {row.customerName}
                            </TableCell>
                            <TableCell>
                                {
                                    row.disciplineCategories
                                        .filter(item => item.count > 0)
                                        .map(item => `${item.name} (${item.count})`)
                                        .join(' - ')
                                }
                            </TableCell>
                            <TableCell width={100}>
                                {editable && <Button size="small" color='primary' onClick={() => onOpenDialog(row)}>
                                    Cập nhật
                                </Button>}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </div>
    </div>
})

const CustomerDisciplineList = (props) => {
    const [filterParams, setFilterParams] = useState({
        q: '',
        checkDate: moment().format('YYYY-MM-DD')
    })

    const onChangeFilter = (field, value) => {
        setFilterParams(params => ({...params, [field]: value}))
    }

    return (<Card>
        <Title title="resources.customer-discipline.name"/>
        <CardContent>
            <Filter {...{filterParams, onChangeFilter}}/>
            <DataTable filterParams={filterParams} permissions={props.permissions}/>
        </CardContent>
    </Card>);
};

export default CustomerDisciplineList
