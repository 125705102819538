import DepartmentIcon from '@material-ui/icons/ViewList';

import SemesterList from './SemesterList';
import SemesterCreate from './SemesterCreate';
import SemesterEdit from './SemesterEdit';

export default {
    list: SemesterList,
    create: SemesterCreate,
    edit: SemesterEdit,
    icon: DepartmentIcon,
};
