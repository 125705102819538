import PartnerDisciplineBatchIcon from '@material-ui/icons/ViewList';

import PartnerDisciplineBatchList from './PartnerDisciplineBatchList';
import PartnerDisciplineBatchCreate from './PartnerDisciplineBatchCreate';
import PartnerDisciplineBatchEdit from './PartnerDisciplineBatchEdit';

export default {
    list: PartnerDisciplineBatchList,
    create: PartnerDisciplineBatchCreate,
    edit: PartnerDisciplineBatchEdit,
    icon: PartnerDisciplineBatchIcon,
};
