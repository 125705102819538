import React, {Fragment} from 'react';
import {Button, fetchEnd, fetchStart, refreshView, showNotification,} from 'react-admin';
import {Grid} from '../components'
import IconCancel from '@material-ui/icons/Cancel';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import './popup.css';
import {change, submit} from "redux-form";
import {compose} from 'redux';
import withStyles from '@material-ui/core/styles/withStyles';
import {connect} from 'react-redux';
import QRCode from "react-qr-code";

const styles = {
    suggestDialog: {
        zIndex: '10 !important',
    }
};
const QrInfoDialog = (props) => {
    const {show, qrInfo, handleCloseDialog, classes,} = props;
    const formatNumber = (num) => {
        return num ? num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : 0;
    }

    return (
        <Fragment>
            <Dialog
                className={classes.suggestDialog}
                fullWidth={true}
                disableBackdropClick={true}
                maxWidth='md'
                open={show}
                onClose={handleCloseDialog}
                aria-label="Thông tin thanh toán"
            >
                {qrInfo &&
                    <>
                        <DialogTitle>Thông tin thanh toán</DialogTitle>
                        <DialogContent>
                            <Grid container spacing={0}>
                                <div style={{textAlign: 'center', width: '100%'}}>
                                    <div style={{margin: "0 auto", height: 120, maxWidth: 120, width: "100%"}}>
                                        {qrInfo.qrCode &&
                                            <QRCode
                                                size={120}
                                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                                value={qrInfo.qrCode}
                                                viewBox={`0 0 120 120`}
                                            />
                                        }
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Tên tài khoản: <span style={{fontWeight: 'bold'}}>{qrInfo.accountName}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Ngân hàng: <span style={{fontWeight: 'bold'}}>{qrInfo.bankName}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Số tài khoản: <span style={{fontWeight: 'bold'}}>{qrInfo.accountNumber}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Số tiền: <span
                                        style={{fontWeight: 'bold'}}>{formatNumber(qrInfo.totalAmount)}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Nội dung chuyển khoản: <span
                                        style={{fontWeight: 'bold'}}>{qrInfo.description}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                       <span
                                           style={{
                                               fontWeight: 'bold',
                                               color: 'red'
                                           }}>{' Mã QR có hiệu lực đến: ' + qrInfo.validTime}</span>
                                    </div>
                                    <div style={{marginTop: 10}}>
                                        Vui lòng sử dụng mã QR này thay cho mã QR trên phiếu báo thu giấy hoặc các mã QR
                                        đã
                                        tạo trước đây.
                                    </div>
                                </div>
                            </Grid>
                        </DialogContent>
                    </>
                }

                <DialogActions>
                    <Button label="ra.action.close" onClick={handleCloseDialog}>
                        <IconCancel/>
                    </Button>
                </DialogActions>
            </Dialog>
        </Fragment>
    );
}
const mapDispatchToProps = {
    change,
    fetchEnd,
    fetchStart,
    submit,
    refreshView,
    showNotification
};
export default compose(connect(null, mapDispatchToProps),
    withStyles(styles))(
    QrInfoDialog
);
